@import url("../../const/const.css");

#proiecte #header .title {
  padding: 0px;
}

#proiecte #header .subtitle {
  font-size: 1.5em;
}

#proiecte .main {
  background-color: var(--primary);
}

#proiecte .content {
  max-width: 1300px;
  padding: 0px 50px;
}

@media (max-width: 1300px) {
  #proiecte .content {
    max-width: 890px;
  }
}

@media (max-width: 890px) {
  #proiecte .content {
    max-width: 480px;
  }
}

#proiecte .content .grid-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1300px) {
  #proiecte .content .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 890px) {
  #proiecte .content .grid-container {
    grid-template-columns: 1fr;
  }
}
