@import url("../../../const/const.css");

#calculator-medie .main-form {
  background-image: url(../../../assets/photos/background-tile.png);
}

#calculator-medie .title {
  text-align: center;
  justify-content: center;
}

#calculator-medie .content {
  max-width: 1200px;
  margin: 20px;
  padding: 20px;
}

#calculator-medie .form {
  width: 100%;
  margin: 0px;
  padding: 3vw;
  color: white;
  background-color: var(--primary);
  border-radius: 10px;
  font-size: 1.3em;
  align-items: flex-start;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
}

#calculator-medie .form hr {
  --color: white;
}

#calculator-medie .form select {
  font-family: var(--font-family-text);
  font-size: 1em;
  background-color: white;
  color: var(--dark);
  padding: 5px;
  margin: 10px 0px;
  border-radius: 15px;
}

#calculator-medie .form .materie-container {
  margin: 5px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

#calculator-medie .form .materie-container .elem.nume {
  flex: 1;
  text-align: left;
}

#calculator-medie .form .materie-container .elem.nota {
  width: 8.5em;
  text-align: center;
}

#calculator-medie .form .materie-container .elem.credite {
  width: 5em;
  text-align: right;
  justify-content: flex-end;
}

#calculator-medie .form .materie-container .elem {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#calculator-medie .form .final {
  width: 100%;
  align-items: flex-start;
}

#calculator-medie .form .final .button-container {
  justify-content: flex-start;
  flex: 1;
  margin-top: 20px;
}

#calculator-medie .form .final .media-container {
  align-items: flex-end;
  margin-right: 10px;
  text-align: right;
  margin-top: 20px;
}

#calculator-medie .form .final .media-container .media {
  font-size: 1.1em;
  font-weight: bold;
  border-top: 1px solid white;
  margin: 10px 0px;
  padding-top: 10px;
  padding-left: 20px;
}

#calculator-medie .form .final .media-container .prev-container {
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
}

#calculator-medie
  .form
  .final
  .media-container
  .prev-container
  .list-container {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 5px;
  margin-left: 5px;
}

@media (max-width: 550px) {
  #calculator-medie .form .materie-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  #calculator-medie .form .final {
    flex-direction: column;
    align-items: center;
  }

  #calculator-medie .form .final .media-container {
    text-align: center;
    align-items: center;
  }

  #calculator-medie .form .final .media-container .media {
    padding-right: 20px;
  }

  #calculator-medie .form .final .media-container .prev-container {
    flex-direction: column;
  }
}
