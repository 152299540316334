@import url("../../../const/const.css");

.cardEveniment {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  color: var(--dark);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  text-decoration: none;
  overflow: hidden;
  margin: 20px;
}

.cardEveniment:hover {
  transform: translate(0px, -10px);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
}

.cardEveniment img {
  width: 40%;
  overflow: hidden;
}

.cardEveniment .container {
  width: 60%;
  padding: 30px 5vw;
  font-size: 1.2em;
}

.cardEveniment .title {
  padding: 30px 20px;
}

.cardEveniment .description {
  text-align: justify;
  margin: 0px;
}

.cardEveniment .description.restrict {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.cardEveniment .access {
  font-size: 0.7em;
}

.cardEveniment .see-more {
  display: inline-block;
  align-self: flex-start;

  padding: 0px;
  margin: 0px;
  margin-bottom: 40px;
  background-color: transparent;
  border: none;
  font-family: var(--font-family-title);
  font-size: 1.1em;
  color: var(--light);
}

.cardEveniment .see-more:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-aspect-ratio: 1/1) {
  .cardEveniment {
    flex-direction: column;
  }

  .cardEveniment img {
    width: 100%;
  }

  .cardEveniment .container {
    width: 100%;
  }
}
