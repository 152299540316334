@import url("../../../const/const.css");

#faq #header .title {
  padding: 0px;
}

#faq #header .subtitle {
  font-size: 1.5em;
}

#faq #header .search-container {
  width: 80%;  
}

#faq .main {
  background-color: var(--primary);
  color: white;
}

#faq .main .content {
  margin: 80px 20px 0px 20px;
  max-width: 1200px;
}

#faq hr {
  --color: white;
}

#faq .content .grid-container {
  font-size: 1.3em;

  display: grid;
  gap: 60px;
  grid-template-columns: 5fr 7fr;
  align-items: start;

  padding-top: 100px;
  padding-bottom: 40px;
}

@media (max-width: 1200px) {
  #faq .content .grid-container {
    grid-template-columns: 1fr;
  }
}

#faq .content a {
  color: inherit;
}

#faq .question {
  text-align: left;
  font-size: 1.5em;
  font-style: italic;
}

#faq .answer {
  text-align: justify;
}

#faq .answer > img {
  width: 100%;
  border-radius: 20px;
  padding: 10px 0px 10px 0px;
}

#faq .answer p {
  margin: 20px;
}
