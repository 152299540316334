@import url("../../const/const.css");

#index #header {
  z-index: 0;
}

#index #header .banner-container {
  max-width: 100%;
}

#index #header .banner-container > img {
  width: 100%;
}

#index #header .title {
  font-weight: bold;
  text-align: center;

  padding: 0px 0px 0px 0px 0px;
  margin-bottom: 200px;
}

#index #header headerMouseScrollDown {
  margin-bottom: 10px;
}
