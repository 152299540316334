@import url("../../../const/const.css");

.cardPerson {
  color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 40px;
  margin: 40px 0px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 90%;
}

.cardPerson .profil {
  width: 70%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
}
.cardPerson .nume {
  padding: 20px 10px 5px 10px;
  color: var(--primary);
  align-items: center;
  text-align: center;
}

.cardPerson .subtitle {
  padding: 0px;
  color: var(--dark);
}
