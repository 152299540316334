@import url("../../../const/const.css");

.cardProiect {
  position: relative;

  justify-content: flex-start;
  overflow: hidden;

  padding-bottom: 20px;
  background-color: var(--primary-hover);
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

  transition: all 0.5s;
}

.cardProiect:hover {
  transform: translate(0px, -10px);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
}

.cardProiect .img-container {
  width: 100%;
  height: 380px;
  background-color: white;
  padding: 10px;
}

.cardProiect .img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cardProiect .title {
  padding: 0px 10px;
  height: 2em;
  align-self: flex-start;
  color: white;
  margin: 20px 0px;
  width: 100%;

  align-items: center;
  text-align: center;
}

.cardProiect p {
  font-size: 1.25em;
  font-style: italic;
  text-align: justify;
  color: white;

  padding: 5px 10px 5px 20px;
  border-left: 5px solid var(--secondary);

  width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.cardProiect a {
  align-self: center;
  text-align: center;

  font-family: var(--font-family-title);
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  text-decoration: none;

  width: 80%;
  padding: 10px;
  margin: 0px 20px;
  background-color: transparent;
  border: 3px solid var(--secondary);
  border-radius: 20px;
  transition: background-color 0.5s;
}

.cardProiect a:hover {
  background-color: var(--secondary);
  cursor: pointer;
}
