@import url(../../const/const.css);

#index #about .main {
  background-color: var(--primary);
}

#index #about .content {
  max-width: 1000px;
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 0px;

  padding: 50px;
}

@media (max-width: 915px) {
  #index #about .content {
    grid-template-columns: 1fr;
  }
}

#index #about .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: 1em;
  color: white;
}

#index #about .container.span {
  grid-column: 1 / span 2;
}

@media (max-width: 915px) {
  #index #about .container.span {
    grid-column: 1 / span 1;
  }
}

#index #about .container > h4 {
  font-family: var(--font-family-title);
  padding-left: 40px;
}

@media (max-width: 915px) {
  #index #about .container > h4 {
    padding-left: 0px;
    text-align: center;
    align-items: center;
  }
}

#index #about .container .img-container img {
  height: 400px;
}

@media (max-width: 915px) {
  #index #about .container .img-container img {
    height: 50vw;
  }
}

#index #about .container > p {
  font-size: 1.3em;
  text-align: justify;
}

#index #about .container > p.left {
  padding: 40px 0px 0px 0px;
  font-style: italic;
}
