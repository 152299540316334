@import url("../../const/const.css");

#evenimente {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#evenimente .calendar-container {
  background-color: var(--primary);
  width: 100%;
  height: 94vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#evenimente .calendar-container #calendar {
  margin-bottom: 20px;
  height: 70vh;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media (min-width: 500px) {
  #evenimente .calendar-container #calendar {
    margin-top: var(--bar-height);
  }
}

#evenimente .calendar-container #calendar .google-calendar {
  width: 1200px;
}

@media (max-width: 1200px) {
  #evenimente .calendar-container #calendar .google-calendar {
    width: 100%;
  }
}

#evenimente #feed .content {
  max-width: 100%;
  padding: 20px;
}
