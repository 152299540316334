@import url("./const/const.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family-text);
  font-size: 1em;
  color: var(--primary);

  background-color: white;
  background-attachment: fixed;
  background-repeat: repeat;
}

@media (max-width: 800px) {
  body {
    font-size: 0.8em;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 0.7em;
  }
}

h1 {
  font-family: var(--font-family-title);
  font-size: 6em;
  margin: 0;
  padding: 0;
}

h2 {
  font-family: var(--font-family-title);
  font-size: 5em;
  margin: 0;
  padding: 0;
}

h3 {
  font-family: var(--font-family-title);
  font-size: 4em;
  margin: 0;
  padding: 0;
}

h4 {
  font-family: var(--font-family-title);
  font-size: 3em;
  margin: 0;
  padding: 0;
}

h5 {
  font-family: var(--font-family-title);
  font-size: 2em;
  margin: 0;
  padding: 0;
}

h6 {
  font-family: var(--font-family-title);
  font-size: 1.1em;
  margin: 0;
  padding: 0;
}

hr {
  width: calc(100% - 2 * 40px);
  border: 0;
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    transparent,
    var(--color),
    transparent
  );
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.stretcher {
  display: flex;
  flex: 1;
}
