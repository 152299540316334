@import url("../../const/const.css");

#doneaza {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;

    margin-bottom: 50px;

    font-size: 1em;
}

#doneaza .myPOS {
    width: min(calc(100% - 50px), 900px);
    margin: 50px;
    align-self: center;
}

#doneaza .select-amount {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

#doneaza .form230 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#doneaza .form230 iframe {
    width: min(calc(100% - 30px), 1000px);
    height: 80vh;
}

@media (max-width: 650px) {
    #doneaza .select-amount {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 380px) {
    #doneaza .select-amount {
        grid-template-columns: 1fr;
    }
}