@import url("../../../const/const.css");

.cardResursa {
  width: 100%;
  padding: 10px;
  color: white;
  width: 100%;
  border: 2px solid white;
  border-radius: 10px;
  text-decoration: none;
  justify-content: flex-start;
  transition: all 0.5s;
  background-color: var(--primary);
}

.cardResursa:hover {
  cursor: pointer;
  background-color: white;
  color: var(--primary);
}

.cardResursa .icon-container {
  margin: 20px;
}
