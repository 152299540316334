@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

:root {
  --primary: #2596be;
  --primary-hover: #00668b;
  --secondary: #8ae8ff;
  --secondary-hover: #75d5ff;

  --dark: #354a54;
  --light: #99aebb;

  --font-family-title: "League Spartan", sans-serif;
  --font-family-text: Lora, serif;

  --bar-height: 60px;
  --bar-color: var(--primary);
  --font-color: white;
}
