@import url("../../const/const.css");

.proiect {
  background-color: var(--primary);
  color: white;
}

.proiect header {
  padding-bottom: 100px;
}

.proiect header .container {
  height: 50vh;
  margin: 50px;
  position: relative;
  background-color: transparent;
}

.proiect header .logo {
  height: 100%;
  background-color: transparent;
}

.proiect header .departament-logo {
  height: 5vh;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
}

.proiect .descriere {
  max-width: 1000px;
  padding: 20px;
  font-size: 1.3em;
  text-align: justify;
}

.proiect .imgs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
}

.proiect .imgs-container img {
  width: 100%;
  padding: 30px 30px 5px 30px;
  border-radius: 40px;
  overflow: hidden;
}

.proiect .imgs-container .img-sub {
  font-style: italic;
  padding-bottom: 10px;
}

.proiect .contact {
  max-width: 400px;
  margin: 20px;
}
