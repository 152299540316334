@import url("../../../const/const.css");

.amfiteatru header {
    background-color: var(--primary);
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    color: white;
  
    padding: 40px 300px;
  
    height: 80vh;
  }
  
  .amfiteatru header .container {
    height: 100%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 100px;
  }
  
  @media (max-width: 1300px) {
    .amfiteatru header {
      flex-direction: column;
      height: auto;
      padding: 30px;
    }
  
    .amfiteatru header .container {
      padding: 0px;
    }
  }
  
  .amfiteatru header .container .title {
    padding-bottom: 70px;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  }
  
  .amfiteatru header .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .amfiteatru header .buttons-container a {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-title);
    font-size: 2em;
    color: var(--dark);
    text-decoration: none;
    white-space: nowrap;
    background-color: var(--secondary);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
  }
  
  .amfiteatru header .buttons-container a:hover {
    background-color: white;
    box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, 0.5);
  }
  
  .amfiteatru header .img-container {
    height: 100%;
  }
  
  .amfiteatru header .img-container img {
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 5%;
    overflow: hidden;
    object-fit: cover;
  }
  
  @media (max-width: 1300px) {
    .amfiteatru header .img-container {
      height: auto;
      width: 90vw;
      margin-bottom: 50px;
    }
    .amfiteatru header .img-container img {
      height: auto;
      width: 100%;
    }
  }
  
  .amfiteatru .main {
    background-color: white;
    color: var(--dark);
  }
  
  .amfiteatru .content {
    width: min(1000px, 95vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  
    padding-bottom: 30px;
  }
  
  .amfiteatru .content .title {
    margin: 30px 0px 100px 0px;
    align-items: center;
    text-align: center;
  }
  
  .amfiteatru .content img {
    width: min(100%, 80vw);
    margin: 20px;
    border-radius: 20px;
  }
  
  .amfiteatru .content video {
    max-height: 80vh;
    max-width: min(100%, 80vw);
    margin: 20px;
    border-radius: 20px;
  }
  