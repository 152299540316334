@import url("../../../const/const.css");

.cardAmfiteatru {
  width: 100%;
  flex-direction: row;
  height: 300px;
  overflow: hidden;
  border-radius: 20px;
  margin: 40px 0px;
  background-color: white;
  color: var(--primary);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  text-decoration: none;
}

.cardAmfiteatru:hover {
  cursor: pointer;
  transform: translate(0px, -10px);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
}

.cardAmfiteatru .img-container {
  width: 40%;
  height: 100%;
  overflow: hidden;
  background-color: var(--light);
}

.cardAmfiteatru .img-container img {
  height: 100%;
  overflow: hidden;
}

.cardAmfiteatru .container {
  width: 60%;
  height: 100%;
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;
}

.cardAmfiteatru .container p {
  font-size: 1.3em;
  color: black;
}

.cardAmfiteatru .buttons-container {
  width: 100%;
  justify-content: center;
}

.cardAmfiteatru .buttons-container a {
  font-family: var(--font-family-title);
  font-size: 1.5em;
  text-decoration: none;
  color: white;
  background-color: var(--primary);
  white-space: nowrap;

  padding: 10px 20px;
  margin: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  transition: all 0.5s;
}

.cardAmfiteatru .buttons-container a:hover {
  background-color: var(--primary-hover);
}

@media (max-width: 500px) {
  .cardAmfiteatru {
    flex-direction: column;
    height: auto;
  }

  .cardAmfiteatru .img-container {
    width: 100%;
    overflow: hidden;
  }

  .cardAmfiteatru .img-container img {
    width: 100%;
  }

  .cardAmfiteatru .container {
    align-items: center;
    text-align: center;
  }

  .cardAmfiteatru .buttons-container a {
    flex-direction: column;
  }
}
