@import url(../../const/const.css);

.navigation-link {
  height: 100%;
  font-family: var(--font-family-title);
  font-weight: bold;
  color: var(--font-color);
  text-decoration: none;
  text-align: center;
  margin: 1px;
  transition: all 0.5s linear;
  font-size: 1.1em;
  z-index: 1;
  overflow: hidden;
}

.navigation-link:hover {
  font-size: 1.18em;
  background-color: var(--secondary-hover);
  cursor: pointer;
}

.navigation-link.highlight {
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(117, 60, 175, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navigation-link.highlight::before {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(117, 60, 175, 1) 20%,
    rgba(117, 60, 175, 1) 50%,
    rgba(117, 60, 175, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
  overflow: hidden;
}

.navigation-link.highlight:hover::before {
  opacity: 1;
}
