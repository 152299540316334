@import url("../../../const/const.css");

.link-button-2 {
  color: white;
  text-decoration: none;
  padding: 15px 40px;
  background-color: var(--primary);
  border: 2px solid transparent;
  outline: none;
  border-radius: 10px;
  font-family: var(--font-family-title);
  font-size: 1.5em;
  font-weight: bold;
  transition: all 0.5s;
  overflow: hidden;
  word-wrap: normal;
}

.link-button-2:hover {
  cursor: pointer;
  background-color: white;
  color: var(--primary);
  border: 2px solid var(--primary);
}
