@import url("../../const/const.css");

#navigation {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  font-size: 1.15em;
}

#navigation .relative-container {
  position: relative;
}

#navigation .container {
  --padding: 40px;
  font-size: 1.2em;
  width: 100%;
  height: var(--bar-height);
  justify-content: flex-start;
  padding: 0px var(--padding) 0px var(--padding);
  background-color: var(--bar-color);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}

#navigation .logo-container {
  --offset: 10px;
}
#navigation .menu-icon-container {
  --offset: 25px;
  margin-left: 20px;
}
#navigation .logo-container,
#navigation .menu-icon-container {
  z-index: 20;

  --size: calc(2 * var(--bar-height) - 2 * var(--offset));

  height: var(--size);
  width: var(--size);
  position: relative;
  top: calc(var(--bar-height) / 2);

  background-color: white;
  border: 3px solid var(--bar-color);
  border-radius: calc(var(--size) / 2);
  overflow: hidden;

  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
}

#navigation .logo-container img {
  width: calc(100% - 2 * var(--margin));
}
#navigation .menu-icon-container img {
  width: calc(80% - 2 * var(--margin));
}
#navigation .logo-container img,
#navigation .menu-icon-container img {
  --margin: 5px;
  margin: var(--margin);
}

#navigation .links-container {
  height: 100%;
  flex: 1;
}

#navigation .links-container.small {
  display: none;
  font-size: 1.3em;
}

@media (max-width: 1400px) {
  #navigation .links-container {
    font-size: 0.8em;
  }
}

@media (max-width: 1250px) {
  #navigation .links-container {
    display: none;
  }

  #navigation .links-container.small {
    display: flex;
  }

  #navigation .container {
    justify-content: space-between;
    --padding: 10px;
  }

  #navigation .menu-icon-container {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  #navigation {
    --bar-height: 70px;
  }

  #navigation .logo-container,
  #navigation .menu-icon-container {
    --offset: 5px;
    --size: calc(var(--bar-height) - 2 * var(--offset));
    top: 0;
  }
}

@media (max-width: 400px) {
  #navigation .links-container.small {
    display: none;
  }
}
