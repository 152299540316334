@import url(../../const/const.css);

#footer {
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: var(--dark);
}

#footer .content {
  padding-left: 20;
  padding-right: 20;
  flex-direction: row;
}

@media (max-width: 1200px) {
  #footer .content {
    flex-direction: column;
  }
}

#footer .logo-container {
  height: 200px;
}

#footer .logo-container > img {
  height: 95%;
  transition: height 0.5s;
}

#footer .logo-container:hover > img {
  height: 100%;
}

#footer .media {
  padding: 30px 10px 10px 10px;
}

#footer .menu {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
}

#footer .menu-section {
  width: 100%;
  background-color: var(--dark);
}
#footer .menu-section.right {
  border-left: 1px solid gray;
}

#footer .menu-section ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  list-style: none;
}

@media (max-width: 500px) {
  #footer .menu {
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
  }

  #footer .menu-section.right {
    border-left: none;
  }

  #footer .menu-section ul {
    padding: 0px;
    text-align: center;
    align-items: center;
  }
}

#footer .menu-section a {
  font-family: var(--font-family-title);
  color: white;
  text-decoration: none;
}
#footer .menu-section a:hover {
  text-decoration: underline;
}

@media (pointer: coarse) {
  #footer .menu-section a {
    font-size: 20px;
  }
}

#footer .copyright {
  padding: 60px 10px 30px 10px;
  background-color: var(--dark);
  text-align: center;
  align-items: center;
}

#footer .copyright hr {
  --color: gray;
}
