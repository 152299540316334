@import url("../../../const/const.css");

#amfiteatre-sali .main {
  background-color: var(--primary);

  padding: 40px 0px;
}

#amfiteatre-sali .content {
  width: 1000px;
}

#amfiteatre-sali #header .title {
  align-items: center;
  text-align: center;
}

@media (max-width: 1000px) {
  #amfiteatre-sali .content {
    width: 100%;
    padding: 0px 20px;
  }
}