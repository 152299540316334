@import url("../../const/const.css");

#resurse #header .title {
  padding: 0px;
}

#resurse #header .subtitle {
  font-size: 1.5em;
}

#resurse .main {
  background-color: var(--primary);
}

#resurse .content {
  max-width: 1200px;

  display: grid;
  gap: 30px;
  grid-template-columns: 380px 380px 380px;

  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1200px) {
  #resurse .content {
    max-width: 800px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px) {
  #resurse .content {
    padding: 100px 20px;
    grid-template-columns: 1fr;
  }
}
