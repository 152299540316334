@import url(../../const/const.css);

#index #resources .main {
  background-color: var(--primary);
  color: white;
}

#index #resources .content {
  max-width: 1300px;

  text-align: center;

  padding-top: 50px;
  padding-bottom: 100px;
}

@media (max-width: 1300px) {
  #index #resources .content {
    max-width: 890px;
  }
}

@media (max-width: 890px) {
  #index #resources .content {
    max-width: 480px;
  }
}

#index #resources .content > p {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0px 20px;
}

#index #resources .grid-container {
  display: grid;
  width: 100%;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 50px;
}

@media (max-width: 1300px) {
  #index #resources .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 890px) {
  #index #resources .grid-container {
    grid-template-columns: 1fr;
  }
}
