@import url(../../../const/const.css);

.nav-menu ul li {
  width: 100%;
}

.nav-menu ul li a {
  width: 100%;
  font-family: var(--font-family-title);
  text-decoration: none;
  padding: 5px 20px 5px 20px;
}

@media (max-width: 1150px) {
  .nav-menu ul li a {
    font-size: 1.4em;
    padding: 15px;
  }
}

.nav-menu ul li a:hover {
  background-image: linear-gradient(
    to right,
    transparent,
    var(--primary-hover)
  );
}

.nav-menu ul li a .title {
  color: var(--font-color);
  flex: 1;
  display: flex;
  flex-direction: row;
}
.nav-menu ul li a .icon-container {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.nav-menu ul li a .guillets {
  color: var(--font-color);
}

.nav-menu a .list {
  position: absolute;
  left: calc(100% - 10px);
}

.nav-menu a:hover > .list > .list-container {
  display: block;
  border-radius: 10px;
  background-color: var(--bar-color);
}

@media (max-width: 1150px) {
  .nav-menu a:hover > .list > .list-container {
    display: none;
  }
}

.nav-menu a > .list > .list-container {
  display: none;
}
