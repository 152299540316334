@import url("../../const/const.css");

#header .img-container {
  display: flex;
}

@media (min-aspect-ratio: 3/2) {
  #header .img-container {
    height: 100vh;
    width: 100%;
  }
}

#header .img-container img {
  position: relative;
  filter: brightness(50%);
}

#header .img-container img {
  width: 100%;
  object-fit: cover;
}

#header .img-container .img-overlay {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#header .img-overlay-alt {
  text-align: center;
  color: white;
  background-color: var(--primary);

  padding: 40px 15px 40px 15px;
}
