@import url("../../const/const.css");

#index #projects {
  color: var(--primary-hover);
  background-color: transparent;
}

#index #projects .content {
  max-width: 1300px;

  text-align: center;

  padding-top: 50px;
  padding-bottom: 100px;
}

@media (max-width: 1300px) {
  #index #projects .content {
    max-width: 890px;
  }
}

@media (max-width: 890px) {
  #index #projects .content {
    max-width: 480px;
  }
}

#index #projects .content > .title {
  text-shadow: 0px 5px 10px rgb(176, 176, 176);
}

#index #projects .content > p {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0px 20px;
  text-shadow: 0px 5px 10px rgb(176, 176, 176);
}

#index #projects .content .grid-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 50px;
}

@media (max-width: 1300px) {
  #index #projects .content .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 890px) {
  #index #projects .content .grid-container {
    grid-template-columns: 1fr;
  }
}

#index #projects .content > a {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  background-color: var(--secondary-hover);
}

#index #projects .content > a:hover {
  background-color: var(--secondary);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
}
