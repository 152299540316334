@import url("../../../const/const.css");

.cardPartener {
  color: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 40px;
  margin: 40px 0px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 90%;
}

.cardPartener .banner {
  width: 100%;
  border-radius: 16px;
  padding: 10px;
}

.cardPartener .nume {
  padding: 20px 10px 5px 10px;
  color: var(--dark);
  align-items: center;
  text-align: center;
}
